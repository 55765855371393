<template>
  <validation-observer
    ref="revenueScheduleSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="revenueScheduleSaveModal"
      body-class="position-static"
      centered
      :title="'Cập nhật dự kiến thu'"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="start_at">
          <template v-slot:label>
            Thời gian bắt đầu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thời gian bắt đầu"
            rules="required"
          >
            <flat-pickr
              v-model="targetRevenueSchedule.start_at"
              :config="config"
              class="form-control"
              placeholder="Thời gian bắt đầu"
              name="start_at"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="start_at">
          <template v-slot:label>
            Thời gian kết thúc <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thời gian kết thúc"
            rules="required"
          >
            <flat-pickr
              v-model="targetRevenueSchedule.end_at"
              :config="config"
              :disabled="!targetRevenueSchedule.start_at"
              class="form-control"
              placeholder="Thời gian kết thúc"
              :min-date="targetRevenueSchedule.start_at"
              name="end_at"
              @on-change="valTime"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('revenueScheduleSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import { vn } from 'flatpickr/dist/l10n/vn.js'

export default {
  name: 'RevenueScheduleSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    revenueSchedule: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetRevenueSchedule: {
        major_id: null,
        revenue_plan_id: null,
        start_at: null,
        end_at: null,
        course_semester_id: null,
        id: null,
      },
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: vn,
        // minDate: 'today',
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
      createRevenueSchedule: 'revenueSchedule/createRevenueSchedule',
      updateRevenueSchedule: 'revenueSchedule/updateRevenueSchedule',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.revenueSchedule) {
        this.targetRevenueSchedule = { ...this.revenueSchedule }
      }
    },
    onHide() {
      this.$refs
        .revenueScheduleSaveFormRef
        .reset()
      this.targetRevenueSchedule = {
        major_id: null,
        revenue_plan_id: null,
        price: null,
        start_at: null,
        end_at: null,
        courseSemesterId: null,
        id: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .revenueScheduleSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.updateRevenueSchedule(this.targetRevenueSchedule)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('revenueScheduleSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    valTime(selectedDates, dateStr, instance) {
      if (dateStr !== '') {
        if (dateStr <= this.targetRevenueSchedule.start_at) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thời gian kết thúc phải lớn hơn bắt đầu!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
